<template>
  <div class="layer_02_mission">
    <div class="layer_top">
      <h2 class="layer_top_title">스탬프 불러오기</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="call_list">
      <div class="call_list_search">
        <form @submit="onSearch">
          <base-select
            :options="searchTypeOptions"
            v-model="search.type"
            placeholder="선택"
          />
          <input
            type="text"
            placeholder="스탬프 명을 검색해 주세요."
            class="list_search_box ml_04"
            v-model="search.q"
          />
          <base-button class="ml_10" type="upload" nativeType="submit"
            >검색</base-button
          >
        </form>
      </div>
      <div>
        <base-table
          :useNo="false"
          :totalItems="totalItems"
          :currentPage="currentPage"
          :pageSize="pageSize"
          :loading="contentLoading"
          :fields="fields"
          :items="items"
          :isEdit="false"
          :isCheckbox="true"
          :defaultChecked="checkedStampIds"
          :selectType="type == 'reward' ? 'radio' : 'checkbox'"
          @onChecked="onChecked"
        />
      </div>
      <base-pagination
        v-if="totalPages > 1"
        :total="totalItems"
        :loading="contentLoading"
        :currentPage="currentPage"
        :pageCount="totalPages"
        :perPage="pageSize"
        @change="fetch"
      />
    </div>
    <div class="btn_center_wrapper mb_30">
      <base-button type="primary" @click.prevent.stop="apply">적용</base-button>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import Dialog from "@/components/Dialog.vue";

export default {
  components: {
    BaseButton,
    BaseTable,
    BasePagination,
    BaseSelect,
  },
  props: {
    stampIds: {
      type: [Array, Number],
      default: () => [],
      description: "stamp ids",
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      checkedStampIds: [],
      general: "general",
      reward: "reward",
      fields: [
        {
          key: "picture",
          width: "100px",
          label: "스탬프",
          tag: "img",
        },
        {
          key: "content.title",
          width: "140px",
          label: "스탬프 명",
        },
        {
          key: "user.name",
          width: "140px",
          label: "등록 담당자",
        },
      ],
      items: [],
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      searchTypeOptions: [
        { name: "등록 담당자", value: "userName" },
        { name: "스탬프 명", value: "name" },
      ],
      search: {
        type: "",
        q: "",
      },
    };
  },
  created() {
    this.checkedStampIds = this.stampIds;
    this.fetch();
  },
  methods: {
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },
    onChecked(stampIds) {
      this.checkedStampIds = stampIds;
    },
    apply() {
      this.$emit("close", {
        stampIds: this.checkedStampIds,
      });
    },
    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = {
        page: this.currentPage,
        size: this.pageSize,
      };

      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      } else if (0 < this.search.q.length) {
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
      }

      if (this.type == "general") {
        await client.generalStampFindAll(params).then(
          (response) => {
            const { data } = response;

            data.items.forEach((item) => {
              // 이미지
              if (item.picture.name) {
                item.picture = `${item.picture.url}${item.picture.path}?size=100`;
              }
            });

            this.items = data.items;
            this.totalItems = data.totalItems;
            this.totalPages = data.totalPages;
            this.contentLoading = false;
          },
          () => {
            // console.log(message);
            this.items = [];
            this.totalItems = 0;
            this.totalPages = 0;
            this.contentLoading = false;
          }
        );
      } else if (this.type == "reward") {
        await client.rewardStampFindAll(params).then(
          (response) => {
            const { data } = response;
            this.items = data.items;
            this.totalItems = data.totalItems;
            this.totalPages = data.totalPages;
            this.contentLoading = false;
          },
          () => {
            // console.log(message);
            this.items = [];
            this.totalItems = 0;
            this.totalPages = 0;
            this.contentLoading = false;
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paging {
  margin-bottom: 15px;
}

.call_list_search {
  .select {
    vertical-align: top;
  }
}
</style>
